






















import { Vue, Component, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import UserService from '@/services/user'
import fetchExchangeQuery from '@/api/husuraApi/getExchangeOrderSummaryApi'

@Component({
  name: 'Container'
  })
export default class extends Vue {
  @Prop({ default: true }) private usePadding !: boolean
  @Prop({ default: 'white' }) private backgroundColor !: string

  private total = ''

  created() {
    this.fetchCenterQuery()
  }

  get isCollapse() {
    return !AppModule.sidebar.opened
  }

  private fetchCenterQuery(refresh = true) {
    const token = UserModule.oauth.accessToken
    const jwt = UserService.decodeToken(token)
    let userDID
    if (jwt) {
      userDID = jwt.sub
    }
    if (!userDID) {
      this.$message.warning('未获取到DID信息，请重新登录')
      return
    }
    fetchExchangeQuery()
      .then(({ data: res }) => {
        const { data } = res
        this.total = data.exchange_order_summary.length
      })
      .catch(error => {
        console.error(error)
      })
  }
}
